<!-- 横向导航栏 -->
<style scoped>
    .logo{
        width: 6rem; 
        z-index: 1000;
    }
    .menu-slide:hover>.menu-slide-item,.menu-slide-item:hover {
        display: block;
    }
    .menu-slide-item>span{
        visibility: hidden;
        opacity: 0;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(1){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(2){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.1s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(3){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.2s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(4){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.3s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(5){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.4s;
    }
    .menu-slide:hover>.menu-slide-item>span:nth-of-type(6){
        visibility:visible;
        animation: menu-slide 0.2s ease-in-out forwards;
        animation-delay: 0.5s;
    }
    @keyframes menu-slide {
        0% {
            opacity: 0;
            transform: scale(1.5);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media (min-width: 576px){
        .logo{
            width: 8rem;
        }
    }
</style>
<template>
    <div class="positoin-relative">
        <header class="py-1 px-2 px-sm-3 pt-sm-0 pb-sm-0 position-relative index-2">
            <div class="w-100 d-flex flex-wrap justify-content-between my-sm-1 my-md-2">
                <div class="d-flex flex-column justify-content-center">
                    <img class="d-none d-sm-block logo me-2 me-sm-3" src="logo.png" alt="">
                </div>
                <div class="d-flex flex-grow-1">
                    <div class="w-0 d-flex flex-column flex-grow-1 text-light mt-0 justify-content-between">
                        <!-- 公告 -->
                        <div class="d-flex align-items-center flex-grow-1 position-relative">
                            <img class="d-block d-sm-none logo me-2 me-sm-3" src="logo.png" alt="">
                            <div v-if="$route.name!='share'" class="flex-grow-1 notice-short border border-1 border-warning rounded-pill d-flex flex-column px-2 overflow-hidden" role="button">
                                <div class="w-100 rounded-pill position-relative width-max marquee text-nowrap">
                                    <label>{{notice}}</label>
                                </div>
                            </div>
                            <div class="notice-long position-absolute bg-dark p-2 rounded w-100 index-1 top-100">{{notice}}</div>
                        </div>
                        <!-- 用户信息 -->
                        <div class="d-flex align-content-center mt-1 ms-1 ms-sm-0 position-relative">
                            <div class="d-flex align-items-center me-2 d-sm-none w-6rem fw-bold text-primary" @click="$root.goPage('home')">
                                <i class="bi bi-house-fill me-1"></i>
                                <span>{{$t("nav.home")}}</span>
                            </div>
                            <div class="me-sm-2 d-flex align-items-center flex-fill flex-sm-grow-0">
                                <i class="bi bi-person-circle me-1 text-info"></i>
                                <span class="align-middle">{{userInfo.id}}</span>
                            </div>
                            <div v-if="!($route.name=='share'||gameModel==GAME_MODEL.tel)" class="me-sm-2 d-flex align-items-center flex-fill flex-sm-grow-0">
                                <i class="bi bi-coin me-1 text-yellow"></i>
                                <span class="me-1 align-middle fw-bold">{{$t("nav.realMoney")}}</span>
                                <span class="align-middle">{{userInfo.realCoin}}</span>
                            </div>
                            <div v-if="!($route.name=='share'||gameModel==GAME_MODEL.tel)" class="d-flex align-items-center flex-fill flex-sm-grow-0">
                                <i class="bi bi-coin me-1 text-yellow"></i>
                                <span class="me-1 align-middle fw-bold">{{$t("nav.esportsMoney")}}</span>
                                <span class="align-middle">{{userInfo.esportsCoin}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center">
                    <div class="d-sm-none dropdown menu-slide">
                        <button class="btn btn-outline-light ms-2 px-2 py-1 position-relative" :class="{'index-1053':$root.teachIndex==2&&$root.isShowTeach}" type="button">
                            <i class="bi bi-list"></i>
                        </button>
                        <div class="dropdown-menu bg-cyan p-2 pb-0 text-center rounded-3 min-w-0 shadow menu-slide-item end-0">
                            <span v-show="['bac','cor'].indexOf($route.name)!=-1" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" role="button" @click="$root.goPage('personal')">{{$t("nav.personal")}}</span>
                            <span v-if="$route.name!='share'" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="setCurrModal($t('nav.network'))" role="button">{{$t("nav.network")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="setCurrModal($t('nav.sound'))" role="button">{{$t("nav.sound")}}</span>
                            <span v-if="$route.name!='share'" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="$root.setFullscreen" role="button">{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}</span>
                            <span v-if="$route.name!='share'" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="$root.logout" role="button">{{$t("nav.logout")}}</span>
                            <span v-show="['bac','cor'].indexOf($route.name)!=-1" class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" role="button" @click="$root.goPage('home')">{{$t("nav.home")}}</span>
                        </div>
                    </div>
                </div>
                <div class="d-sm-flex d-none text-center fw-bold justify-content-end align-items-center">
                    <div class="ps-3" :class="{'index-1053':$root.teachIndex==0&&$root.isShowTeach}">
                        <div class="text-light d-flex flex-column align-items-center align-items-center" role="button" @click="$root.goPage('help')">
                            <i class="bi bi-chat-dots-fill fs-3 text-info position-relative">
                                <span v-show="hasChatMsg" class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"></span>
                            </i>
                            <span>{{$t("nav.chat")}}</span>
                        </div>
                    </div>
                    <div class="ps-3" :class="{'index-1053':$root.teachIndex==1&&$root.isShowTeach}">
                        <div class="text-light d-flex flex-column align-items-center align-items-center" role="button" @click="$root.goPage('personal')">
                            <i class="bi bi-x-diamond-fill fs-3 text-info"></i>
                            <span>{{$t("nav.personal")}}</span>
                        </div>
                    </div>
                    <div class="ps-3 dropdown menu-slide" :class="{'index-1053':$root.teachIndex==2&&$root.isShowTeach}">
                        <span class="text-light d-flex flex-column align-items-center align-items-center" role="button">
                            <i class="bi bi-gear-fill fs-3 text-info"></i>
                            <span>{{$t("nav.settings")}}</span>
                        </span>
                        <div class="dropdown-menu bg-cyan p-2 text-center rounded-3 min-w-0 shadow menu-slide-item translate-middle-x">
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="setCurrModal($t('nav.network'))" role="button">{{$t("nav.network")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="setCurrModal($t('nav.sound'))" role="button">{{$t("nav.sound")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan mb-2 rounded-3 px-4 py-2 shadow" @click="$root.setFullscreen" role="button">{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}</span>
                            <span class="dropdown-item text-light border border-info bg-cyan rounded-3 px-4 py-2 shadow" @click="$root.logout" role="button">{{$t("nav.logout")}}</span>
                        </div>
                    </div>
                    <div class="ps-3">
                        <span class="text-light d-flex flex-column align-items-center align-items-center" role="button" @click="$root.goPage('home')">
                            <i class="bi bi-house-fill fs-3 text-info"></i>
                            <span>{{$t("nav.home")}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
import { MUTATION_TYPE,GAME_MODEL } from "@/assets/js/config.js"
import { guid } from "@/assets/js/common.js"
import { useLayer } from '@/assets/js/useLayer'
export default {
    props:{
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
        hasChatMsg:false,//是否有未读的客服消息
    },
    inject: {
        notice:{
            default:''
        },//公告内容
    },
    data(){
        return {
            GAME_MODEL,
            guid:guid(),
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        videoLineList(){
            return this.$store.state.videoLineList;
        },
        currVideoLine(){
			return this.$store.state.currVideoLine;
		},
        audioSwitch(){
            return this.$store.state.audioSwitch;
        },
        gameModel(){
            return this.$store.state.gameModel;
        }
    },
    methods:{
        /**
         * 修改当前视频线路
         */
        changeVideoLine(lineId){
            this.$root.setCurrVideoLine(lineId);
            useLayer({str:this.$t("nav.videoChangeSucc")});
        },
        /**
         * 改变音效开关
         */
        changeAudioSwitch(type,isOpen){
            this.$store.commit({
                type:MUTATION_TYPE.setAudioSwitch,
                audioSwitch:{
                    [type]:isOpen
                }
            })
        },
        /**
         * 设置当前模态框
         */
        setCurrModal(currModal) {
            this.$store.commit({
                type:MUTATION_TYPE.setCurrModal,
                currModal:currModal
            })
        },
    },
    watch: {
        /**
         * 监听视频线路列表变化
         */
        // videoLineList(newVal) {
            // if(newVal.length>0){
            //     let obj = newVal.find(v=>v.type=="IOS");
            //     if(this.$root.getSystem()=="ios"&&obj){//ios系统如果有IOS线路则使用IOS线路
            //         this.currVideoLine=obj.id;
            //     }else{
            //         this.currVideoLine=newVal[0].id;
            //     }
            //     this.$emit('set-curr-video-line',newVal[0].id);
            // }
        // },
    },
}
</script>