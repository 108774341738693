<style scoped>
    .invalid-tooltip{
        font-size: .7rem !important;
    }
    .register-bg{
        background: url('@/assets/images/login/register_bg.jpg') no-repeat left center;
        background-size: cover;
    }
</style>
<template>
    <div class="d-flex justify-content-center align-items-center h-100 px-2 register-bg">

        <div class="position-absolute top-2rem start-2rem">
            <!-- 语言选择 -->
            <choose-lang></choose-lang>
        </div>

        <div class="d-flex justify-content-center justify-content-sm-end col-12 col-sm-8">
            <div class="card rounded-5 p-2 bg-dark bg-opacity-75 text-secondary">
                <div class="card-body border-1 border-secondary border-dashed rounded-3">
                    <div class="mb-3 text-primary fs-5">
                        <b>{{$t("register.register")}}</b>
                    </div>
                    <form class="d-flex">
                        <div class="d-flex flex-column">
                            <div class="col-auto mb-3 position-relative">
                                <div class="d-flex align-items-center border-bottom">
                                    <i class="bi bi-person fs-5 text-secondary"></i>
                                    <input id="txtAccount" v-model.trim="account" type="text" :class="[isVerify.account?(isValid('account').valid?'is-valid':'is-invalid'):'']" class="form-control form-control-sm border-0 shadow-none bg-transparent text-light" :placeholder="$t('register.enterUser')" minlength="6" maxlength="12" />
                                    <i class="bi bi-dice-6 me-1 text-light" @click="randomAccount"></i>
                                </div>
                                <div class="invalid-tooltip" :class="[isVerify.account&&isValid('account').str?'d-block':'d-none']">{{isValid('account').str}}</div>
                            </div>
                            <div class="col-auto mb-3 position-relative">
                                <div class="d-flex align-items-center border-bottom">
                                    <i class="bi bi-lock fs-5 text-secondary"></i>
                                    <input id="txtPwd" v-model.trim="pwd" type="password" :class="[isVerify.pwd?isValid('pwd').valid?'is-valid':'is-invalid':'']" class="form-control form-control-sm border-0 shadow-none bg-transparent text-light" :placeholder="$t('register.enterLoginPwd')" minlength="6" maxlength="12" />
                                </div>
                                <div class="invalid-tooltip" :class="[isVerify.pwd&&isValid('pwd').str?'d-block':'d-none']">{{isValid('pwd').str}}</div>
                            </div>
                            <div class="col-auto mb-3 position-relative">
                                <div class="d-flex align-items-center border-bottom">
                                    <i class="bi bi-lock fs-5 text-secondary"></i>
                                    <input id="txtPwdAgain" v-model.trim="pwdAgain" type="password" :class="[isVerify.pwdAgain?isValid('pwdAgain').valid?'is-valid':'is-invalid':'']" class="form-control form-control-sm border-0 shadow-none bg-transparent text-light" :placeholder="$t('register.enterLoginPwdAgain')" minlength="6" maxlength="12" />
                                </div>
                                <div class="invalid-tooltip" :class="[isVerify.pwdAgain&&isValid('pwdAgain').str?'d-block':'d-none']">{{isValid('pwdAgain').str}}</div>
                            </div>
                            <div class="col-auto mb-3 position-relative">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <div class="d-flex align-items-center border-bottom">
                                            <i class="bi bi-shield fs-5 text-secondary"></i>
                                            <input id="txtCode" v-model.trim="txtCode" type="text" :class="[isVerify.txtCode?isValid('txtCode').valid?'is-valid':'is-invalid':'']" class="form-control form-control-sm border-0 shadow-none bg-transparent text-light" maxlength="4" :placeholder="$t('register.enterCode')" />
                                        </div>
                                        <div class="invalid-tooltip" :class="[isVerify.txtCode&&isValid('txtCode').str?'d-block':'d-none']">{{isValid('txtCode').str}}</div>
                                    </div>
                                    <div class="d-flex flex-column w-8rem rounded border ms-3">
                                        <canvas id="code" class="d-block bg-white rounded-2 w-100 h-0 flex-grow-1" width="0" height="0" @click="code=drawCode($event.target)"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto mb-1 position-relative d-flex">
                                <label class="col-form-label col-form-label-sm fw-bold">{{$t("login.chooseNetwork")}}</label>
                                <div class="form-control-sm">
                                    <div v-for="(item,key,i) in wsList" :key="(item,key,i)" class="form-check form-check-inline">
                                        <input class="form-check-input me-1" type="radio" :value="item" v-model="ws" name="wsline" :id="'wsLine_'+i">
                                        <label class="form-check-label" :for="'wsLine_'+i">{{key}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto mb-2">
                                <div class="form-check small mb-0 pe-3">
                                    <input class="form-check-input" type="checkbox" v-model="isAgree" id="cboAgreement">
                                    <label class="form-check-label small" for="cboAgreement">{{$t("register.agree")}}<a id="agreement" href="#">{{$t("register.agreement")}}</a></label>
                                </div>
                            </div>
                            <div class="col-auto mb-2">
                                <button id="btnRegister" type="button" class="btn btn-success btn-sm me-2" @click="register">
                                    <i class="bi bi-pencil-square me-1"></i>
                                    <span>{{$t("register.register")}}</span>
                                </button>
                                <button id="btnReset" type="reset" class="btn btn-warning btn-sm" @click="reset">
                                    <i class="bi bi-arrow-repeat me-1"></i>
                                    <span>{{$t("register.reset")}}</span>
                                </button>
                            </div>
                            <div class="col-auto mb-2">
                                <button type="button" class="btn btn-link btn-sm small" @click="$router.push({name:'login'})">{{$t("register.havingAccount")}}</button>
                            </div>
                        </div>
                    </form>
    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useLayer } from '@/assets/js/useLayer';
import { USER,IS_DEBUG,COMMAND,LAYER_TYPE,LANG } from "@/assets/js/config.js"
import { getDeviceType,drawCode } from '@/assets/js/common.js'
import chooseLang from "@/components/public/ChooseLang.vue";
export default {
    components: { chooseLang },
    props: {
        str:{
            // type:String,
            default:""
        },//路由参数（推荐人）
    },
    data() {
        return {
            account:"",//账号
            pwd:"",//密码
            pwdAgain:"",//确认密码
            // getPwd:"",//提款密码
            // getPwdAgain:"",//确认提款密码
            // phone:"",//手机号码
            txtCode:"",//验证码
            isAgree:true,//同意协议
            code:"",//生成的验证码
            wsList:window[USER].ws,//config中配置的ws列表
            ws:"",
            lang:LANG,//获取语言配置
            isShowMoreLang:false,//是否显示其他语言
            langClass:"",//语言class名称，为了有延迟效果
            isVerify:{
                account:false,
                pwd:false,
                pwdAgain:false,
                // getPwd:false,
                // getPwdAgain:false,
                // phone:false,
                txtCode:false
            },//是否显示验证
        };
    },
    created() {
        //设置默认选中第一个ws协议
        for (let key in this.wsList) {
            this.ws=this.wsList[key];
            break;
        }
    },
    mounted() {
        this.code = drawCode(document.getElementById("code"));
    },
    methods: {
        drawCode,
        /**
         * 注册
         */
        register(){
            this.isVerify.account=true;
            this.isVerify.pwd=true;
            this.isVerify.pwdAgain=true;
            // this.isVerify.getPwd=true;
            // this.isVerify.getPwdAgain=true;
            // this.isVerify.phone=true;
            this.isVerify.txtCode=true;
            if(!this.isAgree){
                useLayer({str:this.$t("register.agreeErr")});
                return false;
            }
            if(this.isValid("account").valid&&
            this.isValid("pwd").valid&&
            this.isValid("pwdAgain").valid&&
            // this.isValid("getPwd").valid&&
            // this.isValid("getPwdAgain").valid&&
            // this.isValid("phone").valid&&
            this.isValid("txtCode").valid){
                let ws=new WebSocket(this.ws);
                useLayer({type:LAYER_TYPE.load,str:this.$t("register.connServer")});
                ws.onopen= ()=> {
                    IS_DEBUG&&console.log("连接建立");
                    useLayer({type:LAYER_TYPE.load,str:this.$t("register.registering")});
                    // ws.send(COMMAND.all_register+"|"+this.userName+"|"+this.pwd+"|"+this.getPwd+"|"+this.phone+"||"+this.str+"|"+this.$root.ip.cip+"|"+getDeviceType()+";")
                    console.log(COMMAND.all_register+"|"+this.account+"|"+this.pwd+"||||"+this.$route.href.substring(this.$route.href.lastIndexOf('/')+1)+"|"+this.$root.ip.cip+"|"+getDeviceType()+";")
                    ws.send(COMMAND.all_register+"|"+this.account+"|"+this.pwd+"||||"+this.$route.href.substring(this.$route.href.lastIndexOf('/')+1)+"|"+this.$root.ip.cip+"|"+getDeviceType()+";")
                    // this.$emit("ws-send",COMMAND.all_register, this.userName, this.pwd,this.getPwd,this.phone,"",this.str, this.$root.ip.cip, this.$root.getDeviceType());
                }
                ws.onmessage=(e)=>{
                    IS_DEBUG&&console.log(e.data);
                    let data=JSON.parse(e.data);
                    if(data.command==COMMAND.all_register){
                        if(data.result=="1"){
                            useLayer({str:this.$t("register.registerSuccess")});
                        }else if(data.result=="-2"){
                            useLayer({str:this.$t("register.userExists")});
                        }else if(data.result=="-3"){
                            useLayer({str:this.$t("register.shareCodeErr")});
                        }else{
                            useLayer({str:this.$t("register.registerFail")});
                        }
                    }
                }
            }

        },
        /**
         * 重置
         */
        reset(){
            this.account="";
            this.pwd="";
            this.pwdAgain="";
            this.getPwd="";
            this.getPwdAgain="";
            this.phone="";
            this.txtCode="";
            this.isAgree=true;
        },
        /**
         * 参数是否有效
         */
        isValid(param){
            let valid={
                valid:true,//是否有效
                str:""//无效字符串
            }
            switch (param) {
                case "account":
                    if(!this.account){
                        valid.valid=false;
                        valid.str=this.$t("login.userEmpty");
                    }else if(this.account.length<6||this.account.length>12||!/^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,12}$/.test(this.account)){
                        valid.valid=false;
                        valid.str=this.$t("register.userErr");
                    }
                    break;
                case "pwd":
                    if(!this.pwd){
                        valid.valid=false;
                        valid.str=this.$t("register.loginPwdEmpty");
                    }else if(this.pwd.length<6||this.pwd.length>12){
                        valid.valid=false;
                        valid.str=this.$t("register.loginPwdErr");
                    }
                    break;
                case "pwdAgain":
                    if(!this.pwdAgain){
                        valid.valid=false;
                        valid.str=this.$t("register.loginPwdAgainEmpty");
                    }else if(this.pwdAgain!=this.pwd){
                        valid.valid=false;
                        valid.str=this.$t("register.loginPwdAgainErr");
                    }
                    break;
                // case "getPwd":
                //     if(!this.getPwd){
                //         valid.valid=false;
                //         valid.str=this.$t("register.withdrawPwdEmpty");
                //     }else if(this.getPwd.length<6||this.getPwd.length>12){
                //         valid.valid=false;
                //         valid.str=this.$t("register.withdrawPwdErr");
                //     }
                //     break;
                // case "getPwdAgain":
                //     if(!this.getPwdAgain){
                //         valid.valid=false;
                //         valid.str=this.$t("register.withdrawPwdAgainEmpty");
                //     }else if(this.getPwdAgain!=this.getPwd){
                //         valid.valid=false;
                //         valid.str=this.$t("register.withdrawPwdAgainErr");
                //     }
                //     break;
                // case "phone":
                //     if(!this.phone){
                //         valid.valid=false;
                //         valid.str=this.$t("register.phoneEmpty");
                //     }else if(!(/^1[34578]\d{9}$/.test(this.phone))){
                //         valid.valid=false;
                //         valid.str=this.$t("register.phoneErr");
                //     }
                //     break;
                case "txtCode":
                    if(!this.txtCode){
                        valid.valid=false;
                        valid.str=this.$t("register.codeEmpty");
                    }else if(this.txtCode.toLowerCase()!=this.code.toLowerCase()){
                        valid.valid=false;
                        valid.str=this.$t("login.codeVerify");
                    }
                    break;
            }
            return valid;
        },
        /**
         * 生成随机用户名
         */
        randomAccount(){
            let letter = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];//字母
            let letterLength=Math.floor(Math.random()*10+2);//随机2~11位字母长度
            let numberLength=12-letterLength;//数字补足12位长度
            let letterIndex=Math.floor(Math.random() * (letter.length-letterLength));
            this.account="";
            for (let i = letterIndex; i < letterIndex+letterLength; i++) {
                this.account+=letter[i];
            }
            for (let i = 0; i < numberLength; i++) {
                this.account+=i;
            }
        }
    },
    watch: {
        account() {
            this.isVerify.account=true;
        },
        pwd(newVal) {
            if(newVal.length>5){
                this.isVerify.pwd=true;
            }
        },
        pwdAgain() {
            this.isVerify.pwdAgain=true;
        },
        // getPwd() {
        //     this.isVerify.getPwd=true;
        // },
        // getPwdAgain() {
        //     this.isVerify.getPwdAgain=true;
        // },
        // phone() {
        //     this.isVerify.phone=true;
        // },
        txtCode(newVal) {
            if(newVal.length==this.code.length){//验证码长度与生成的验证码长度一致才做验证
                this.isVerify.txtCode=true;
            }else{
                this.isVerify.txtCode=false;
            }
        },
    },
};
</script>