<style scoped>
    .game-list-item:not(.active)::before{
        content:"";
        display: block;
        background: rgba(0,0,0,.4);
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .game-list-item:hover::before{
        background: rgba(0,0,0,.2);
    }
    .text-3d>div{
        font-family:cursive;
        background-clip: text;
        -webkit-background-clip: text;
        grid-area: overlap;
        letter-spacing: 1px;
        -webkit-text-stroke: .2rem transparent;
    }
    .text-3d-bg{
        background-image: repeating-linear-gradient( 105deg, #ffb338 0% , #3e2904 5%, rgb(233, 233, 0) 12%);
        color: transparent;
        transform: scaleY(1.05);
        transform-origin: top;
    }
    .text-3d-fg{
        background-image: repeating-linear-gradient( 5deg, #ffb338 0% , #77571d 23%, yellow 31%);
        color: #2b3038;
        transform: scale(1);
    }
    .bg-gradient-both{
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    }
    .line{
        content: "";
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,.3),rgba(255,255,255,0));
    }
    .game-item-hover:hover{
        background: linear-gradient(180deg,rgba(108,171,163,1) 0%,rgba(128,213,209,1) 20%, rgba(72,118,157,1) 100%);
    }
    @media (max-width: 575px){
		.more-game{
            transform: translate(-100%,0);
            position: absolute;
        }
        .more-game.offset{
            animation: offset .25s cubic-bezier(.25, 1.15, .35, 1.08) forwards;
            background: rgba(0,0,0,.5);
        }
        .more-game-switch{
            transform: translate(100%,-100%);
        }
        /* .offset-room{
            animation: offset-room .25s cubic-bezier(.25, 1.15, .35, 1.08) forwards;
        } */
    }
    @keyframes offset {
        from {}
        to {
            /* position: relative; */
            transform: translate(0,0);
        }
    }
    /* @keyframes offset-room {
        from {}
        to {
            transform: translate(24%,0);
        }
    } */
</style>
<template>
    <div class="d-flex flex-column h-100">
        <div class="flex-grow-1 h-0">
            <div v-show="isShowMoreGame" class="position-fixed start-0 end-0 top-0 bottom-0 bg-black bg-opacity-50 index-1 d-sm-none"></div>
            <div class="d-flex flex-column h-100">

                <!-- 横向导航 -->
                <navbar class="order-first" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen"></navbar>

                <!-- 主内容 -->
                <div class="d-flex flex-grow-1 position-relative px-sm-0">
    
                    <!-- 游戏列表 -->
                    <div @click.stop class="d-flex flex-column more-game h-100 index-1 bg-gradient-both" :class="[isShowMoreGame?'offset':'']">
                        <div class="flex-grow-1 h-0">
                            <div @click.stop="showMoreGame" class="more-game-switch d-flex position-absolute top-50 text-vertical end-0 bg-danger text-light rounded-end px-1 py-2 border border-2 border-warning border-start-0 text-center lh-sm d-sm-none" role="button">
                                <span>{{$t("home.moreGame")}}</span>
                            </div>
                            <ul class="h-100 list-unstyled d-flex flex-column text-nowrap m-0 fw-bold text-center overflow-y-scroll scroll-none">
                                <li v-for="item in gameList" :key="item" @click="getRoomList(item)" class="text-light game-item-hover" :class="[item==activeGame?'bg-cyan':'opacity-50']" role="button">
                                    <div class="py-3 px-3">{{$t("common."+GAME_CODE[item])}}</div>
                                    <div class="line"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <!-- 台面列表 -->
                    <div :class="{'offset-room':isShowMoreGame}" class="flex-grow-1 h-100 d-flex flex-column">
                        
                        <!-- 电话模式下显示的视频 -->
                        <div v-if="gameModel==GAME_MODEL.tel" class="d-flex flex-column flex-sm-row h-sm-50 px-2 mb-2">
                            <media-video id="video1" class="rounded-5 overflow-hidden me-2" :video-index="0" :video-list="videoList" :room-class="['d-none']" :time-class="['d-none']" :img-class="['position-absolute translate-middle top-50 start-50']"></media-video>
                            <media-video id="video2" class="rounded-5 overflow-hidden" :video-index="1" :video-list="videoList" :room-class="['d-none']" :time-class="['d-none']" :img-class="['position-absolute translate-middle top-50 start-50']"></media-video>
                        </div>
                        
                        <!-- 台面列表 -->
                        <div class="flex-grow-1 h-0">
                            <room-list :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj" @get-room-pearl="getRoomPearl" @on-ready="isRoomlistReady=true" :class="{'col-12 col-sm-6':$root.hasPearl(activeGame)}"></room-list>
                        </div>
                    
                    </div>
    
                </div>
            </div>
        </div>
        <foot-nav></foot-nav>
    </div>
</template>
<script>
import {COMMAND,GAME_ID,GAME_TYPE, RESULT,LAYER_TYPE,GAME_CODE, IS_DEBUG,USER,MUTATION_TYPE, GAME_MODEL} from "@/assets/js/config.js"
import navbar from "@/components/public/navbar.vue";
import roomList from '@/components/public/roomList.vue';
import footNav from '@/components/public/footNav.vue';
import mediaVideo from "@/components/public/video.vue";
import { useLayer } from '@/assets/js/useLayer';
import { getSystem } from "@/assets/js/common.js";
export default {
    components: { navbar,roomList,footNav,mediaVideo },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            GAME_CODE,
            GAME_MODEL,
            countList:[],//路单统计列表
            pearlObj: {
                id:0,//台面id
                pearl:[]//路单数据
            },//要重绘路单的对象
            isShowMoreGame:false,//是否显示更多游戏
            isRoomlistReady:false,//台面列表组件是否加载完成
            videoList:[],//大厅视频线路地址，当前支持最多两个
            countdownWorkerList:[],//台面计时器webWorker对象列表
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        activeGame(){
            return this.$store.state.activeGame;
        },
        videoLineList(){
            return this.$store.state.videoLineList;
        },
        currVideoLine(){
			return this.$store.state.currVideoLine;
		},
        gameList(){
            return this.$store.state.gameList;
        },
        gameModel(){
            return this.$store.state.gameModel;
        },
        roomList(){
            return this.$store.state.roomList;
        },
        tabGame(){
            return this.$store.state.tabGame;
        },
    },
    mounted() {
        //获取视频线路
        this.$emit('ws-send',COMMAND.all_getline,this.userInfo.id, this.userInfo.guid);
        //获取游戏列表
        !this.gameList&&this.$emit('ws-send', COMMAND.all_getgamelist, GAME_TYPE.all, this.userInfo.id, this.userInfo.guid);
        //获取用户信息
        this.$emit("ws-send",COMMAND.all_getuserinfo,this.userInfo.id, this.userInfo.guid);
        //获取台面列表（判断是否相等是防止相同游戏列表台返回厅时，重新加载列表但列表key绑定的是id，id不变所以不触发获取路，此举可减少获取和加载次数）
        if((!this.roomList)||(this.tabGame!=0&&this.activeGame!=this.tabGame)){
            this.$emit("ws-send",COMMAND.all_getroomlist, this.activeGame,this.userInfo.id, this.userInfo.guid);
        }
        //设置响应事件
        this.setWsMessage();
    },
    methods: {
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$emit("ws-msg",(data)=>{
                switch (data.command) {
                    case COMMAND.all_getgamelist:
                        this.$store.commit({
                            type:MUTATION_TYPE.setGameList,
                            gameList:data.game[GAME_TYPE.real].concat(data.game[GAME_TYPE.esports]).filter(v=>GAME_CODE[v])
                        })
                        break;
                    case COMMAND.all_getroompearl:
                        this.pearlObj={
                            id:data.id,
                            pearl:data.pearl
                        }
                        this.countList[data.id]=data.count;
                        break;
                    case COMMAND.all_entertable:
                        if(data.result==RESULT.enterTable.unmoney){
                            useLayer({str:this.$t("common.unMoney")});
                            return;
                        }else if(data.result!=RESULT.enterTable.success){
                            useLayer({str:this.$t("home.goRoomErr")});
                            return;
                        }
                        //有线路列表并且当前没有选择视频线路时，自动配置默认线路
                        if(this.videoLineList&&(!this.currVideoLine)){
                            this.$root.setCurrVideoLine(this.getDefaultLineId(data.rid));
                        }
                        this.$nextTick(()=>{
                            let room=this.roomList.find(v=>v.id==data.id);
                            if(room){
                                this.$store.commit({
                                    type:MUTATION_TYPE.setRoomInfo,
                                    roomInfo:room
                                })
                                this.$root.goPage(GAME_CODE[this.activeGame]);
                            }
                        })
                        break;
                    case COMMAND.all_refresh:
                    case COMMAND.bac_gameresult:
                    case COMMAND.cor_gameresult:
                    case COMMAND.dice_gameresult:
                    case COMMAND.rou_gameresult:
                        typeof this.roomList.find(v=>v.id==data.id)!="undefined"&&this.getRoomPearl(this.activeGame,data.id,this.activeGame==GAME_ID.bac?200:this.$root.columnList[data.id]);//台面存在且可见
                        break;
                    case COMMAND.all_changeboot:
                        if(typeof this.roomList.find(v=>v.id==data.id)!="undefined"){//台面存在且可见
                            //清空路单
                            this.pearlObj={
                                id:data.id,
                                pearl:[]
                            }
                            //更新靴局
                            this.$store.commit({
                                type:MUTATION_TYPE.setRoomList,
                                room:{
                                    id:data.id,
                                    boot:data.boot,
                                    innings:data.innings
                                }
                            })
                            //更新统计
                            this.countList[data.id]=[0,0,0];
                        }
                        break;
                    case COMMAND.all_getline:
                        this.$store.commit({
                            type:MUTATION_TYPE.setVideoLineList,
                            videoLineList:data.list
                        })

                        if(this.gameModel==GAME_MODEL.tel){//电话模式
                            let id=this.currVideoLine;//获取当前选中的视频线路
                            if(!id){//当前没有选中的视频线路，则设置默认线路
                                //由于大厅有视频，所以需要设置默认线路
                                this.$root.setCurrVideoLine(this.getDefaultLineId(0));
                            }
                            //获取大厅视频
                            this.$emit("ws-send",COMMAND.all_getvideo,0,id,this.userInfo.id,this.userInfo.guid);
                        }

                        break;
                    //电话模式处理
                    case COMMAND.all_getvideo:
                        let videoArr=data.video;
                        let list=[];//拼装好的视频地址数组
                        for (let i = 0; i < videoArr.length; i++) {
                            list.push(data.flvline+'/'+videoArr[i]+'.flv');
                        }
                        this.videoList=list;
                        break;
                    case COMMAND.all_tableupdate:
                        if(this.roomList.find(v=>v.id==data.tableid)){
                            this.$store.commit({
                                type:MUTATION_TYPE.setRoomList,
                                room:{
                                    id:data.id,
                                    currnum:data.usercount
                                }
                            })
                        }
                        break;
                }
            })
        },
        /**
         * 显示更多游戏
         */
        showMoreGame(){
            this.isShowMoreGame=this.isShowMoreGame?false:true;
            if(this.isShowMoreGame){//显示状态下点击空白处隐藏
                document.onclick=(e)=>{
                    e.stopPropagation();
                    this.isShowMoreGame=false;
                }
            }
        },
        /**
         * 获取游戏对应台面列表
         */
        getRoomList(gameId){
            if(this.isRoomlistReady){//当页面内组件加载完成时才能再次获取，避免频繁获取
                this.isRoomlistReady=false;
                (!this.$root.changeGame(gameId,"activeGame"))&&(this.isRoomlistReady=true);//切换不成功需要把组件加载状态置回空闲，否则下次无法操作
            }
        },
        /**
         * 获取台面路单
         * @param {Number} tableId 台面id
         * @param {Number} column 获取的数据列数
         */
        getRoomPearl(gameId,tableId,column){
            this.activeGame==GAME_ID.bac&&(column=200);//bac路子有特殊性，所以不能根据列数取
            //获取台面路单
            this.$emit("ws-send",COMMAND.all_getroompearl, gameId,tableId,column,"list", this.userInfo.id, this.userInfo.guid);
        },
        /**
         * 根据线路列表自动取默认线路id
         */
        getDefaultLineId(roomId){
            let localLine=localStorage.getItem("videoline");
            if(localLine&&this.videoLineList.find(v=>v.id==localLine)){//有缓存线路并且缓存的线路在线路列表中
                return localLine;//则仍使用缓存的线路
            }
            let lineArr = this.videoLineList.filter(v=>v.rids.indexOf(roomId)!=-1);//获取和当前要进入的台面匹配的视频线路列表
            if(this.videoLineList.length>0){
                let iosLine=lineArr.find(v=>v.type=="IOS");//获取ios线路
                if(getSystem()=="ios"&&iosLine){//如果当前为ios系统并且线路列表中有IOS线路则使用IOS线路
                    return iosLine.id;
                }else{
                    return lineArr.length>0?lineArr[0].id:this.videoLineList[0].id;
                }
            }
            return "";
        },
        
    },
    watch: {
        /**
         * 监听视频线路id改变
         */
        currVideoLine(newVal){
            if(this.gameModel==GAME_MODEL.tel){//电话模式
                if(newVal!=""){
                    //获取大厅视频
                    this.$emit("ws-send",COMMAND.all_getvideo,0,this.currVideoLine,this.userInfo.id,this.userInfo.guid);
                }else{
                    this.videoList=null;
                }
            }
        }
    },
}
</script>