import { LAYER_TYPE } from "@/assets/js/const.js";
// function LayerObject(){
//     return {
//         type:LAYER_TYPE,
//         str:String,
//         btn:Array,
//         yesFn:Function
//     }
// }
let LayerObject={
    type:LAYER_TYPE,
    str:String,
    btn:Array,
    yesFn:Function
}
export function useLayer(obj=LayerObject) {
    let lay;//弹出层对象
    (!obj.type)&&(obj.type=LAYER_TYPE.info);
    (!obj.str)&&(obj.str="");
    layer.closeAll();
    if(obj.type==LAYER_TYPE.info){//信息提示
        lay = layer.open({
            content: obj.str,
            skin: 'msg',
            time: 3,
            style:'margin-bottom:10rem'
        });
    }else if(obj.type==LAYER_TYPE.confirm){//消息确认
        if(getComputedStyle(document.body).width.replace("px","")<576){
            //小屏幕用底部弹出层
            lay = layer.open({
                content:obj.str,
                skin:'footer',
                btn:obj.btn,
                shadeClose:false,
                yes:(i)=>{
                    obj.yesFn(),
                    layer.close(i);
                },
                no:(i)=>{
                    layer.close(i);
                }
            })
        }else{
            //非小屏幕用中间弹出层
            lay = layer.open({
                content:obj.str,
                btn:obj.btn,
                style:"width:25rem",
                shadeClose:false,
                yes:(i)=>{
                    obj.yesFn(),
                    layer.close(i);
                },
                no:(i)=>{
                    layer.close(i);
                }
            })
        }
    }else if(obj.type==LAYER_TYPE.load){//加载框
        lay = layer.open({
            type:LAYER_TYPE.load,
            content:obj.str,
            shadeClose:false
        })
    }
    return lay;
}